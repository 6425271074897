import React, { forwardRef } from 'react';
import styled from 'styled-components';
import ScNoStyleButton from './sc/ScNoStyleButton';
import { ReactComponent as IconDoubleChevron } from '../assets/img/icon_double_chevron.svg';

const ScButton = styled(ScNoStyleButton)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2b2b2b;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: 0 0 20px 20px;
`;

const QuotOpenButton = forwardRef((props, ref) => {
  const handleClick = () => {
    const el = ref.current;
    if (!el) return;
    el.scrollTop = el.scrollHeight;
  };

  return (
    <ScButton onClick={handleClick}>
      <IconDoubleChevron />
    </ScButton>
  );
});

export default QuotOpenButton;
