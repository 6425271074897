import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Contributor from './Contributor';
import ContributorDetails from './ContributorDetails';
import {
  getPlaylistsContentData,
  getCurrentViewingPlaylistID,
} from '../store/selectors';
import { BREAKPOINTS } from '../constants';

const StyledContributors = styled.div`
  flex-basis: 50%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    align-items: flex-start;
  }
`;

const ScAbc = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Contributers = () => {
  const contents = useSelector(getPlaylistsContentData);
  const currentViewingPlaylistID = useSelector(getCurrentViewingPlaylistID);
  const currentContent = contents[currentViewingPlaylistID];
  const [currentView, setCurrentView] = useState('curator');

  const changeView = (view) => setCurrentView(view);

  useEffect(() => {
    changeView('curator');
  }, [currentContent]);

  return currentContent ? (
    <StyledContributors>
      <ScAbc>
        <Contributor
          contributorData={currentContent.curator}
          onClick={() => changeView('curator')}
        />
        {currentContent.illustrator.full_name
          && currentContent.illustrator.full_name
            !== currentContent.curator.full_name && (
            <Contributor
              contributorData={currentContent.illustrator}
              onClick={() => changeView('illustrator')}
            />
        )}
      </ScAbc>
      <ContributorDetails contributorData={currentContent[currentView]} />
    </StyledContributors>
  ) : null;
};

export default Contributers;
