import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shape } from 'prop-types';
import { changePlaylist, changeTab } from '../store/ui/ui';
import PlaylistPlayable from './PlaylistPlayable';
import PlayableItem from './PlayableItem';
import {
  getCurrentApp,
  getCurrentViewingPlaylistID,
  getPlaylistsContentData,
  getScreenType,
  getSpotifyState,
} from '../store/selectors';
import { APP_NAMES, SCREEN_TYPES, TABS } from '../constants';

const PlaylistItem = ({ data }) => {
  const dispatch = useDispatch();
  const contents = useSelector(getPlaylistsContentData);
  const spotifyState = useSelector(getSpotifyState);
  const currentViewingPlaylistID = useSelector(getCurrentViewingPlaylistID);
  const screenType = useSelector(getScreenType);

  const isCurrentPlaylist = currentViewingPlaylistID === data.id;
  const isCurrentPlayingPlaylist = spotifyState?.context?.uri === data.uri;
  const showPlayable = isCurrentPlayingPlaylist;

  const currentApp = useSelector(getCurrentApp);
  const isCurrentAppOriginals = currentApp === APP_NAMES.ORIGINALS;

  const handleTabItemClick = (id, uri) => {
    dispatch(changePlaylist(id, uri));

    if (screenType === SCREEN_TYPES.XS_SCREEN ) {
      dispatch(changeTab(TABS.TRACKS));
    }
  };

  const getPrimaryText = () => {
    if (isCurrentAppOriginals) {
      return data && contents[data.id]?.title;
    }
    return data.name;
  }

  const getSecondaryText = () => {
    if (isCurrentAppOriginals) {
      return data && contents[data.id]?.genre[0];
    }
    return '';
  };

  return (
    <PlayableItem
      imgUrl={data.images[0].url}
      imgAlt={data.name}
      onClick={() => handleTabItemClick(data.id, data.uri)}
      primaryText={getPrimaryText()}
      showPlayable={showPlayable}
      showSecondaryText={isCurrentAppOriginals}
      secondaryText={getSecondaryText()}
      isCurrentItem={isCurrentPlaylist}
      PlayableRenderer={() => <PlaylistPlayable id={data.id} uri={data.uri} />}
    />
  );
};

PlaylistItem.propTypes = {
  data: shape().isRequired,
};

export default PlaylistItem;
