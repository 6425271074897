import { nextPlayed } from '../player';
import { flowiumPlayer } from './initPlayer';

const playNextURI = () => (dispatch, getState) => {
  if (!getState().player.spotifyState) return;

  flowiumPlayer.nextTrack();
  dispatch(nextPlayed());
};

export default playNextURI;
