import React from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import ScIconWrapper from './sc/ScIconWrapper';

import { ReactComponent as IconChevron } from '../assets/img/icon_chevron.svg';

const ScCollapseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0 32px;
`;

const ScCollapseButton = styled(ScIconWrapper)`
  transform: ${({ isQuotBoxCollapsed }) =>
    isQuotBoxCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)'};
`;

const CollapseButton = ({
  isQuotBoxCollapsed,
  onCollapseClick
}) => {
  return (
    <ScCollapseButtonWrapper>
    <ScCollapseButton
      width="10px"
      height="auto"
      fill="#666666"
      onClick={onCollapseClick}
      isQuotBoxCollapsed={isQuotBoxCollapsed}
    >
      <IconChevron />
    </ScCollapseButton>
  </ScCollapseButtonWrapper>
  );
};

CollapseButton.propTypes = {
  isQuotBoxCollapsed: bool,
  onCollapseClick: func
};

CollapseButton.defaultProps = {
  isQuotBoxCollapsed: false,
  onCollapseClick: f => f
};

export default CollapseButton;