import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScControls from './sc/scControls';
import ScIconWrapper from '../sc/ScIconWrapper';

import playURI from '../../store/player/actionCreators/playURI';
import pauseURI from '../../store/player/actionCreators/pauseURI';
import resumeURI from '../../store/player/actionCreators/resumeURI';
import playNextURI from '../../store/player/actionCreators/playNextURI';
import playPrevURI from '../../store/player/actionCreators/playPrevURI';
import toggleShuffle from '../../store/player/actionCreators/toggleShuffle';
import toggleRepeatMode from '../../store/player/actionCreators/toggleRepeatMode';

import { ReactComponent as IconShuffle } from '../../assets/img/icon_shuffle.svg';

import { ReactComponent as IconPrev } from '../../assets/img/icon_prev.svg';
import { ReactComponent as IconPlay } from '../../assets/img/icon_play.svg';
import { ReactComponent as IconPause } from '../../assets/img/icon_pause.svg';
import { ReactComponent as IconNext } from '../../assets/img/icon_next.svg';

import { ReactComponent as IconRepeat } from '../../assets/img/icon_repeat.svg';
import { ReactComponent as IconRepeatContext } from '../../assets/img/icon_repeat_context.svg';
import { ReactComponent as IconRepeatTrack } from '../../assets/img/icon_repeat_track.svg';

import { getSpotifyState, getCurrentViewingPlaylistURI } from '../../store/selectors';

const Controls = () => {
  const dispatch = useDispatch();
  const spotifyState = useSelector(getSpotifyState);
  const spotifyURI = useSelector(getCurrentViewingPlaylistURI);

  // play / pause / resume
  const handlePlayClick = () => {
    if (!spotifyState) {
      dispatch(playURI(spotifyURI));
      return;
    }

    if (!spotifyState.paused) {
      dispatch(pauseURI());
    } else {
      dispatch(resumeURI());
    }
  };

  // prev
  const handePrevClick = () => {
    dispatch(playPrevURI());
  };

  // next
  const handleNextClick = () => {
    dispatch(playNextURI());
  };

  const handleShuffleClick = () => {
    dispatch(toggleShuffle());
  };

  const handleRepatModeClick = () => {
    dispatch(toggleRepeatMode());
  };

  return (
    <ScControls>
      {/* shuffle */}
      <ScIconWrapper
        width="15px"
        height="12px"
        isActive={spotifyState && spotifyState.shuffle}
        onClick={handleShuffleClick}
        activeFill="#bd3030"
        activeFillHover="#e53f3f"
      >
        <IconShuffle id="icon-shuffle" />
      </ScIconWrapper>
      {/* prev */}
      <ScIconWrapper width="13px" height="14px" onClick={handePrevClick}>
        <IconPrev id="icon-prev" />
      </ScIconWrapper>
      {/* play */}
      <ScIconWrapper
        width="17px"
        height="20px"
        onClick={handlePlayClick}
        isActive
      >
        {!spotifyState && <IconPlay id="icon-play" />}
        {spotifyState && spotifyState.paused && <IconPlay id="icon-play" />}
        {spotifyState && !spotifyState.paused && <IconPause id="icon-pause" />}
      </ScIconWrapper>
      {/* next */}
      <ScIconWrapper width="13px" height="14px" onClick={handleNextClick}>
        <IconNext id="icon-next" />
      </ScIconWrapper>
      {/* repeat */}
      <ScIconWrapper
        width="19px"
        height="15px"
        onClick={handleRepatModeClick}
        isActive={spotifyState && spotifyState.repeat_mode}
        activeFill="#bd3030"
        activeFillHover="#e53f3f"
      >
        {!spotifyState && <IconRepeat id="icon-repeat" />}
        {spotifyState && spotifyState.repeat_mode === 0 && <IconRepeat id="icon-repeat" />}
        {spotifyState && spotifyState.repeat_mode === 1 && (
        <IconRepeatContext id="icon-repeat" />
        )}
        {spotifyState && spotifyState.repeat_mode === 2 && (
        <IconRepeatTrack id="icon-repeat" />
        )}
      </ScIconWrapper>
    </ScControls>
  );
};

export default Controls;
