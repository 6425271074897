import {
  // get
  trackSaveStatusRequested,
  trackSaveStatusFulfilled,
  trackSaveStatusRejected,
  // save
  trackSaveRequested,
  trackSaveFulfilled,
  trackSaveRejected,
  // remove
  trackRemoveRequested,
  trackRemoveFulfilled,
  trackRemoveRejected,
} from '../player';
import { spotifyApiPending } from '../../spotifyApi';
import { createNotification } from '../../ui/ui';
import { notificationContentEN } from '../../../constants';

// get current track save
export const getCurrentTrackSave = (spotifyState) => (dispatch) => {
  const trackID = spotifyState?.track_window?.current_track?.id;

  if(!trackID) return;

  const encodedIds = encodeURI([trackID].join(','));

  return dispatch(
    spotifyApiPending({
      url: '/v1/me/tracks/contains',
      params: { ids: encodedIds },
      onPending: trackSaveStatusRequested.type,
      onFulfilled: trackSaveStatusFulfilled.type,
      onRejected: trackSaveStatusRejected.type,
    }),
  );
};

// add current track save
export const addCurrentTrackSave = (trackID) => (dispatch) => {
  dispatch(createNotification({ message: notificationContentEN.TRACK_LIKED }));
  return dispatch(
    spotifyApiPending({
      url: '/v1/me/tracks',
      method: 'put',
      params: { ids: trackID },
      onPending: trackSaveRequested.type,
      onFulfilled: trackSaveFulfilled.type,
      onRejected: trackSaveRejected.type,
    }),
  );
};

// remove current track save
export const removeCurrentTrackSave = (trackID) => (dispatch) => {
  dispatch(createNotification({ message: notificationContentEN.TRACK_UNLIKED }));
  return dispatch(
    spotifyApiPending({
      url: '/v1/me/tracks',
      method: 'delete',
      params: { ids: trackID },
      onPending: trackRemoveRequested.type,
      onFulfilled: trackRemoveFulfilled.type,
      onRejected: trackRemoveRejected.type,
    }),
  );
};

// toggle current track save
export const toggleCurrentTrackSave = () => (dispatch, getState) => {
  const { spotifyState } = getState().player;
  if (!spotifyState) return;

  const trackID = spotifyState.track_window.current_track.id;
  const { isCurentTrackSaved } = getState().player;

  if (isCurentTrackSaved) {
    return dispatch(removeCurrentTrackSave(trackID));
  } else {
    return dispatch(addCurrentTrackSave(trackID));
  }
};

