import { useEffect, useMemo, useState } from 'react';
import throttle from 'lodash/throttle';

const useFixedOnScroll = (viewPortDistence, scrollableElSelector, elRef, throttleRate = 50) => {
  const [isFixed, setIsFixed] = useState(false);
  const scrollEl = useMemo(() => global.document.querySelector(scrollableElSelector), [scrollableElSelector]);
  const [scrollableScrollTopDistence, setScrollableScrollTopDistence] = useState(0);

  useEffect(() => {
    if (!scrollEl || !elRef.current) return;

    const handleScroll = ({ target }) => {
      const elDomRect = elRef.current.getBoundingClientRect();

      // fixed item according to viewport distence
      if (elDomRect.y <= viewPortDistence && !isFixed) {
        setIsFixed(true);
        setScrollableScrollTopDistence(target.scrollTop);
        return;
      }

      // unfixed the item according to the scrollable's distence when the time the item was fixed
      if (target.scrollTop < scrollableScrollTopDistence && isFixed) {
        setIsFixed(false);
        return;
      }

    };

    const throttledHandleScroll = throttle(handleScroll, throttleRate);
    scrollEl.addEventListener("scroll", throttledHandleScroll);
    return () => scrollEl.removeEventListener("scroll", throttledHandleScroll);
  });

  return isFixed;
};

export default useFixedOnScroll;
