import { TABS } from "../constants";
import { fetchSelectionsPlaylists } from "./entities/playlists";
import { changeTab, toggleApp } from "./ui/ui";

const toggleFlowium = () => (dispatch, getState) => {
  return dispatch(toggleApp())
    .then(() => dispatch(changeTab(TABS.PLAYLISTS)))
    .then(() => dispatch(fetchSelectionsPlaylists()));
};

export default toggleFlowium;
