import { volumeChanged } from '../player';
import { flowiumPlayer } from './initPlayer';

const changeVolume = (volumePercent) => (dispatch, getState) => {
  if (!getState().auth.user) return;

  flowiumPlayer.setVolume(parseFloat(volumePercent / 100)).then(() => {
    dispatch(volumeChanged({ volumePercent }))
  });
};

export default changeVolume;
