import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFixedOnScroll from '../hooks/useFixedOnScroll';
import { changePlayButtonPosition } from '../store/ui/ui';
import { getCurrentViewingPlaylistURI, getSpotifyState } from '../store/selectors';

import { ReactComponent as IconPlayXl } from '../assets/img/icon_play_xl.svg';
import { ReactComponent as IconPauseXl } from '../assets/img/icon_pause_xl.svg';

import playURI from '../store/player/actionCreators/playURI';
import pauseURI from '../store/player/actionCreators/pauseURI';
import resumeURI from '../store/player/actionCreators/resumeURI';
import ScNoStyleButton from './sc/ScNoStyleButton';

const MobilePlayButton = () => {
  const buttonRef = useRef();
  const isPlayButtonFixed = useFixedOnScroll(74, '#innerScrollList', buttonRef);
  const dispatch = useDispatch();

  const spotifyState = useSelector(getSpotifyState);
  const spotifyURI = useSelector(getCurrentViewingPlaylistURI);
  const playerContextURI = spotifyState?.context?.uri;
  const isCurrentViewingListOnPlayer = playerContextURI === spotifyURI;

  useEffect(() => {
    dispatch(changePlayButtonPosition(isPlayButtonFixed));
  }, [isPlayButtonFixed, dispatch]);

  // play / pause / resume
  const handlePlayClick = () => {
    if (!spotifyState || !isCurrentViewingListOnPlayer) {
      dispatch(playURI(spotifyURI));
      return;
    }

    if (isCurrentViewingListOnPlayer) {
      if (!spotifyState.paused) {
        dispatch(pauseURI());
      } else {
        dispatch(resumeURI());
      }
    }
  };

  return (
    <ScNoStyleButton onClick={handlePlayClick} ref={buttonRef} >
      {(!spotifyState || spotifyState?.paused || !isCurrentViewingListOnPlayer) && <IconPlayXl />}
      {spotifyState && isCurrentViewingListOnPlayer && !spotifyState?.paused && <IconPauseXl />}
    </ScNoStyleButton>
  );
};

export default MobilePlayButton;
