import { useEffect } from 'react';

const useHandleClickOutside = (ref, cb, exceptionalElSelector, additionalCondition) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const exceptionalEl = document.querySelector(exceptionalElSelector);
      if(exceptionalEl.contains(event.target)) return;

      if (
        ref &&
        ref.current &&
        !ref.current.contains(event.target) &&
        additionalCondition
      ) {
        if (typeof cb === 'function') {
          cb(event);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [cb, ref, exceptionalElSelector, additionalCondition]);
};

export default useHandleClickOutside;
