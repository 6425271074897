import React, { useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import SettingsButtonStandalone from './SettingsButtonStandalone';
import signOutFlowium from '../store/auth/signOutFlowium';
import { toggleAccountBox } from '../store/ui/ui';
import { getSpotifyUser, getIsAccountBoxOpen } from '../store/selectors';
import { BREAKPOINTS } from '../constants';
import useHandleClickOutside from '../hooks/useHandleClickOutside';

const ScMenuWrapper = styled.div`
  position: relative;
  margin: 0 21px 0 28px;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    margin: 0;
  }
`;

const ScMenu = styled.ul`
  width: 192px;
  padding: 15px 10px;
  list-style-type: none;
  background: #2b2b2b;
  border-radius: 5px;
  margin: 22px 0 0 0;
  position: absolute;
  top: 100%;
  right: -19px;
  z-index: 99;

  display: ${({ isAccountBoxOpen }) => `${isAccountBoxOpen ? 'block' : 'none'};`}
    @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    display: block;
    padding: 23px 20px;
    border-radius: 10px 0 0 10px;
    background: #1b1b1b;
    width: 300px;
    height: calc(100vh - 119px);
    right: -10px;
    margin: 29px 0 0 0;
    transition: 200ms ease-out;
    transform: ${({ isAccountBoxOpen }) => `${isAccountBoxOpen ? 'translateX(0%)' : 'translateX(100%)'};`};
  }

  li {
    margin-bottom: 25px;

    @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
      padding-bottom: 19px;
      border-bottom: 2px solid #2b2b2b;
      margin-bottom: 23px;
    }

    &.name {
      color: #dadada;
      border-bottom: 1px solid #1b1b1b;
      padding: 0 0 12px 5px;
      margin-bottom: 15px;

      @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
        background: #1b1b1b;
        border-bottom: 1px solid #2b2b2b;
        padding: 0 0 19px 0;
        margin-bottom: 23px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.xs-no-border {
      border: none;
    }
  }

  li,
  a,
  button {
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    color: #bababa;

    @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
      font-size: 18px;
    }
  }

  a:focus,
  a:hover,
  a:active,
  a.secondary:focus,
  a.secondary:hover,
  a.secondary:active,
  button:focus,
  button:hover,
  button:active {
    text-decoration: none;
    color: #dadada;
  }

  a.secondary {
    font-size: 12px;
    color: #606060;

    @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
      font-size: 14px;
    }
  }

  button {
    background: none;
    border: none;
    padding: 0;

    &.supportButton {
      width: 100%;
      padding: 9px 0;
      color: #dadada;
      border: 1px solid #dadada;
      border-radius: 5px;
    }
  }
`;

const AccountBoxMember = () => {
  const dispatch = useDispatch();
  const { display_name: displayName = '' } = useSelector(getSpotifyUser) || {};
  const isAccountBoxOpen = useSelector(getIsAccountBoxOpen);
  const menuRef = useRef();

  const onMenuClickOutside = () => {
    dispatch(toggleAccountBox());
  };

  useHandleClickOutside(menuRef, onMenuClickOutside, '.js-settings', isAccountBoxOpen);

  return (
    <ScMenuWrapper>
      <SettingsButtonStandalone
        isOpen={isAccountBoxOpen}
        onClick={() => dispatch(toggleAccountBox())}
      />
      <ScMenu isAccountBoxOpen={isAccountBoxOpen} ref={menuRef}>
        <li className="name">
          Hello,
          {' '}
          {displayName}
        </li>
        <li>
          <a href="/">Submit Illustration</a>
        </li>
        <li>
          <a href="/">Submit Playlist</a>
        </li>
        <li className="signoutItem">
          <button type="button" onClick={() => dispatch(signOutFlowium())}>
            Log out
          </button>
        </li>

        <li className="xs-no-border">
          <button className="supportButton" type="button">
            Patreon
          </button>
        </li>
        <li className="xs-no-border">
          <a
            className="secondary"
            href="https://www.spotify.com/us/account/apps/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Remove Flowium
          </a>
        </li>
      </ScMenu>
    </ScMenuWrapper>
  );
};

export default AccountBoxMember;
