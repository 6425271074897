import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import AccountBox from './AccountBox';
import AccountBoxGuest from './AccountBoxGuest';
import AccountBoxMember from './AccountBoxMember';
import FlowiumLogo from './FlowiumLogo';
import { ReactComponent as IconSpotify } from '../assets/img/icon_spotify.svg';
import { BREAKPOINTS } from '../constants';
import { getUser } from '../store/selectors';

const ScAccountBox = styled.div``;

const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 66px;
  align-items: center;
  box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 99;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    height: 60px;
  }
`;

// ${ScAccountBox} {
//   display: none;
// }

const Header = () => {
  const user = useSelector(getUser);

  return (
    <StyledHeader>
      <FlowiumLogo />
      <IconSpotify />
      <ScAccountBox>
        <AccountBox>
          {user ? <AccountBoxMember /> : <AccountBoxGuest />}
        </AccountBox>
      </ScAccountBox>
    </StyledHeader>
  );
};

export default Header;
