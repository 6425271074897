import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Quotation from './Quotation';
import Contributers from './Contributors';
import { BREAKPOINTS, SCREEN_TYPES, TABS } from '../constants';
import {
  getScreenType,
  getIsQuotBoxCollapsed,
} from '../store/selectors';
import { changeTab, toggleQuotBox } from '../store/ui/ui';
import PlaylistTitleMobile from './PlaylistTitleMobile';
import CollapseButton from './CollapseButton';

const ScQuotWrapper = styled.div`
  display: flex;
  padding: 20px;
  height: 350px;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    flex-direction: column;
    padding: 0 10px 0;
    height: auto;
  }
`;

const ScCollapseWrapper = styled.div`
  display: flex;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    flex-direction: column;
    height: ${({ isCollapsed }) => (isCollapsed ? '39px' : 'initial')};
    overflow: hidden;
  }
`;

const QuotBox = () => {
  const screenType = useSelector(getScreenType);
  const isQuotBoxCollapsed = useSelector(getIsQuotBoxCollapsed);
  const { XS_SCREEN } = SCREEN_TYPES;
  const dispatch = useDispatch();

  const handleCollapseClick = () => {
    dispatch(toggleQuotBox());
  };

  const handleBackClick = () => {
    dispatch(changeTab(TABS.PLAYLISTS));
  };

  return (
    <ScQuotWrapper>
      {screenType === XS_SCREEN && (
        <PlaylistTitleMobile onBackClick={handleBackClick} />
      )}
      <ScCollapseWrapper isCollapsed={isQuotBoxCollapsed}>
        <Quotation />
        <Contributers />
      </ScCollapseWrapper>
      <CollapseButton onCollapseClick={handleCollapseClick} />
    </ScQuotWrapper>
  );
};

export default QuotBox;
