import React from 'react';
import { shape, func } from 'prop-types';
import styled from 'styled-components';
import { BREAKPOINTS } from '../constants';

const StyledContributor = styled.div`
  color: #dadada;
  margin: 0 0 0 20px;
  cursor: pointer;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    margin: 0 20px 0 0;
  }

  .contributor-info {
    height: 46px;
    position: relative;
    line-height: 1;

    & .contributer-info-wrapper {
      display: flex;
      flex-direction: row;
    }

    & img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    & .contributor-box {
      padding: 0 0 8px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & .contributor-name {
      font-size: 14px;
      font-weight: 700;
      color: #dadada;
      text-transform: capitalize;
      margin-bottom: 3px;
    }

    & .contributor-title {
      font-size: 10px;
      color: #dadada;
      text-transform: capitalize;
    }
  }
`;

const Contributer = ({ contributorData, onClick }) => {
  const { full_name: fullName, title, avatar_url: avatarUrl } = contributorData;

  return (
    <StyledContributor onClick={onClick}>
      <div className="contributor-info">
        <div className="contributer-info-wrapper">
          <img src={avatarUrl} alt={fullName} />
          <div className="contributor-box">
            <div className="contributor-name">{fullName}</div>
            <div className="contributor-title">{title}</div>
          </div>
        </div>
      </div>
    </StyledContributor>
  );
};

Contributer.propTypes = {
  contributorData: shape(),
  onClick: func,
};

Contributer.defaultProps = {
  contributorData: {},
  onClick: (f) => f,
};

export default Contributer;
