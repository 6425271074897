import { useEffect, useMemo, useState } from 'react';
import throttle from "lodash/throttle";

const useFixedOnScrollInContainer = (scrollDistance, scrollableElSelector, throttleRate = 50) => {
  const [isFixed, setIsFixed] = useState(false);
  const el = useMemo(() => global.document.querySelector(scrollableElSelector), [scrollableElSelector]);

  useEffect(() => {
    if(!el) return;

    const handleScroll = (e) => {
      const elScrollTop = e.target.scrollTop;
      if (elScrollTop > scrollDistance && !isFixed) {
        setIsFixed(true);
        return;
      }
      if (elScrollTop <= scrollDistance && isFixed) {
        setIsFixed(false);
      }
    };

    const throttledHandleScroll = throttle(handleScroll, throttleRate);
    el.addEventListener("scroll", throttledHandleScroll);
    return () => el.removeEventListener("scroll", throttledHandleScroll);
  });

  return isFixed;
};

export default useFixedOnScrollInContainer;
