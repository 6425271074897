import React from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import PlaylistTitle from './PlaylistTitle';
import ScIconWrapper from './sc/ScIconWrapper';

import { ReactComponent as IconChevron } from '../assets/img/icon_chevron.svg';

const ScIconChevronWrapper = styled(ScIconWrapper)``;

const ScFixedTitleWrapper = styled.div`
  display: ${({ isFixed }) => (isFixed ? 'block' : 'none')};
  width: calc(100% - 20px);
  position: fixed;
  top: 60px;
  left: 10px;
  z-index: 5;
  padding-bottom: 37px;
  background: #000000;
`;

const ScFixedTitle = styled.h1`
  background-color: #2b2b2b;
  font-size: 16px;
  padding: 13px 74px 11px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 800;
  color: #ffffff;
  line-height: 1.2;
  margin: 0;
  position: relative;
  height: 62px;
  display: flex;
  align-items: center;

  & ${ScIconChevronWrapper} {
    margin-right: 18px;
  }
`;

const ScTitle = styled.h1`
  font-size: 18px;
  font-weight: 800;
  color: #ffffff;
  line-height: 1.2;
  margin: 15px 0 7px;
`;

const PlaylistTitleMobile = ({ onBackClick }) => {
  return (
    <PlaylistTitle
      TitleRenderer={(props) => <ScTitle {...props}>{props.children}</ScTitle>}
      FixedTitleRenderer={(props) => (
        <ScFixedTitleWrapper {...props}>
          <ScFixedTitle>
            <ScIconChevronWrapper onClick={onBackClick}>
              <IconChevron />
            </ScIconChevronWrapper>
            {props.children}
          </ScFixedTitle>
        </ScFixedTitleWrapper>
      )}
    />
  );
};

PlaylistTitleMobile.propTypes = {
  onBackClick: func
};

PlaylistTitleMobile.defaultProps = {
  onBackClick: f => f
};

export default PlaylistTitleMobile;
