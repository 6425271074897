import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { SCREEN_TYPES } from '../constants';
import { getScreenType } from '../store/selectors';

const MobilePortal = ({ children, portalDomNodeSelector }) => {
  const screenType = useSelector(getScreenType);
  const domNode = global.document.querySelector(portalDomNodeSelector);
  const { XS_SCREEN } = SCREEN_TYPES;

  if (screenType === XS_SCREEN) {
    return ReactDOM.createPortal(children, domNode);
  }

  return children;
};

export default MobilePortal;
