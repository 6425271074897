import React from 'react';
import { useSelector } from 'react-redux';
import { node } from 'prop-types';
import { getActiveTab } from '../store/selectors';
import TabsHeader from './TabsHeader';
import MobilePortal from './MobilePortal';

const Tabs = ({ children }) => {
  const activeTab = useSelector(getActiveTab);

  return (
    <>
      <MobilePortal portalDomNodeSelector="#mobile-bottom-nav">
        <TabsHeader>{children}</TabsHeader>
      </MobilePortal>
      {children.find((tab) => tab.props.tabKey === activeTab)}
    </>
  );
};

Tabs.propTypes = {
  children: node,
};

Tabs.defaultProps = {
  children: null,
};

export default Tabs;
