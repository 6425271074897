import {
  func, bool, string, elementType,
} from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../constants';

const StyledTabItem = styled.li`
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 5px;
  background: #1b1b1b;
  background: ${({ isCurrentItem }) => (isCurrentItem ? '#2B2B2B' : '#1B1B1B')};

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: #2b2b2b;
  }

  & .playable-container {
    display: ${({ showPlayable }) => (showPlayable ? 'flex' : 'none')};
  }

  &:hover .playable-container {
    display: flex;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px;

  &:hover {
    & .title {
      color: #ffffff;
    }
    & .genre {
      color: #dadada;
    }
  }

  & .title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 5px;
  }

  & .genre {
    display: block;
    font-size: 12px;
    text-transform: capitalize;
    color: #dadada;
  }
`;

const StyledImgWrapper = styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 12px;

  & img {
    width: 50px;
    height: 50px;
  }

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    flex-shrink: 0;
  }
`;

const StyledContentWrapper = styled.div`
  position: relative;
  width: 230px;
  padding-right: 48px;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    width: calc(100% - 62px);
  }
`;

const PlayableItem = ({
  imgUrl,
  imgAlt,
  onClick,
  primaryText,
  showPlayable,
  secondaryText,
  isCurrentItem,
  PlayableRenderer,
  showSecondaryText,
}) => (
  <StyledTabItem isCurrentItem={isCurrentItem} onClick={onClick}>
    <StyledWrapper>
      <StyledImgWrapper>
        <img src={imgUrl} alt={imgAlt} />
      </StyledImgWrapper>
      <StyledContentWrapper showPlayable={showPlayable}>
        <span className="title">{primaryText}</span>
        <span className="genre">{showSecondaryText && secondaryText}</span>
        <div className="playable-container">
          <PlayableRenderer />
        </div>
      </StyledContentWrapper>
    </StyledWrapper>
  </StyledTabItem>
);

PlayableItem.propTypes = {
  imgUrl: string.isRequired,
  imgAlt: string.isRequired,
  onClick: func.isRequired,
  showPlayable: bool,
  secondaryText: string,
  isCurrentItem: bool.isRequired,
  PlayableRenderer: elementType.isRequired,
};

PlayableItem.defaultProps = {
  showPlayable: false,
  primaryText: '',
  secondaryText: '',
  showSecondaryText: true
};

export default PlayableItem;
