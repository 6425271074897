import { spotifyApiPending } from '../../spotifyApi';

const toggleShuffle = () => (dispatch, getState) => {
  if (!getState().player.spotifyState) return;

  const { deviceID } = getState().player;
  const currentShuffle = getState().player.spotifyState.shuffle;

  dispatch(
    spotifyApiPending({
      url: '/v1/me/player/shuffle',
      method: 'put',
      params: { id: deviceID, state: !currentShuffle },
    }),
  );
};

export default toggleShuffle;
