import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ScProgressBar from './sc/scProgressBar';
import { getSpotifyState } from '../../store/selectors';

const ProgressBar = () => {
  const spotifyState = useSelector(getSpotifyState);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!spotifyState) return;
    if (!spotifyState.paused) return;

    const pos = spotifyState.position;
    const dur = spotifyState.duration;

    setCurrentTime(pos);
    setDuration(dur);
  }, [spotifyState]);

  useEffect(() => {
    if (!spotifyState) return;
    if (spotifyState.paused) return;

    if (spotifyState.position === 0) {
      setCurrentTime(0);
      setDuration(spotifyState.duration);
      return;
    }

    const TIMER_MS = 250;
    const timer = () => {
      const interval = setInterval(() => {
        setCurrentTime((currTime) => currTime + TIMER_MS);
      }, TIMER_MS);
      return interval;
    };
    const trackInterval = timer();

    // eslint-disable-next-line consistent-return
    return () => clearInterval(trackInterval);
  }, [spotifyState]);

  const calculateBarPercent = () => {
    const percent = +((currentTime / duration) * 100).toFixed(4);
    return -100 + percent;
  };

  const getTrackTimeInMinutes = (dur) => moment.duration(dur).minutes();
  const getTrackTimeSeconds = (dur) => {
    const seconds = moment.duration(dur).seconds();
    if (seconds < 10) return `0${seconds}`;
    return seconds;
  };

  return (
    <ScProgressBar>
      {spotifyState && (
      <span className="time current-time">
        <span className="minutes">
          {getTrackTimeInMinutes(currentTime)}
        </span>
        :
        <span className="seconds">
          {getTrackTimeSeconds(currentTime)}
        </span>
      </span>
      )}
      <div className="bar-wrapper">
        <div className="bar track-bar" />
        <div
          className="bar track-progress"
          style={{ transform: `translateX(${calculateBarPercent()}%)` }}
        />
      </div>
      {spotifyState && (
      <span className="time total-time">
        <span className="minutes">
          {getTrackTimeInMinutes(spotifyState.duration)}
        </span>
        :
        <span className="seconds">
          {getTrackTimeSeconds(spotifyState.duration)}
        </span>
      </span>
      )}
    </ScProgressBar>
  );
};

export default ProgressBar;
