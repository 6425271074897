import React from 'react';
import styled from 'styled-components';
import { bool, func } from 'prop-types';
import ScNoStyleButton from './sc/ScNoStyleButton';

import { ReactComponent as IconPauseMain } from '../assets/img/icon_pause_main.svg';
import { ReactComponent as IconPlayMain } from '../assets/img/icon_play_main.svg';

const ControlButton = styled(ScNoStyleButton)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const StyledPlayable = styled.div`
  position: absolute;
  top: 11px;
  right: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Playable = ({ onClick, renderPauseCondition }) => (
  <StyledPlayable onClick={onClick}>
    <ControlButton width="24px" height="27px">
      {renderPauseCondition ? <IconPauseMain /> : <IconPlayMain />}
    </ControlButton>
  </StyledPlayable>
);

Playable.propTypes = {
  renderPauseCondition: bool,
  onClick: func.isRequired,
};

Playable.defaultProps = {
  renderPauseCondition: false,
};

export default Playable;
