import { node } from 'prop-types';
import React from 'react';

const Tab = ({ children }) => <>{children}</>;

Tab.propTypes = {
  children: node,
};

Tab.defaultProps = {
  children: null,
};

export default Tab;
