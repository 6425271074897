import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SCREEN_TYPES } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTab, getScreenType } from '../store/selectors';
import MobilePortal from './MobilePortal';
import { collapseQuotBox, showQuotBox } from '../store/ui/ui';
import Artwork from './Artwork';
import PlaylistTitle from './PlaylistTitle';

const ScTitle = styled.h1`
  font-size: 24px;
  font-weight: 800;
  color: #bababa;
  line-height: 1.35;
  margin: 6px 0;
`;

const Content = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);
  const screenType = useSelector(getScreenType);
  const { XS_SCREEN } = SCREEN_TYPES;

  // init quot box collapse
  useEffect(() => {
    if (screenType === XS_SCREEN) {
      dispatch(collapseQuotBox());
    } else {
      dispatch(showQuotBox());
    }
  }, [XS_SCREEN, screenType, dispatch]);

  return (
    <>
      {activeTab === 'tracks' && (
        <MobilePortal portalDomNodeSelector="#mobile-artwork-container">
          <div className="content-area">
            {screenType !== XS_SCREEN && (
              <PlaylistTitle TitleRenderer={ScTitle} />
            )}
            <Artwork />
          </div>
        </MobilePortal>
      )}
      {activeTab === 'playlists' && screenType !== XS_SCREEN && (
        <div className="content-area">
          <PlaylistTitle TitleRenderer={ScTitle} />
          <Artwork />
        </div>
      )}
    </>
  );
};

export default Content;

