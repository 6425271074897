import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shape } from 'prop-types';
import { getSpotifyState } from '../store/selectors';
import { playTrack } from '../store/entities/tracks';
import TrackPlayable from './TrackPlayable';
import PlayableItem from './PlayableItem';

const TrackItem = ({ data }) => {
  const dispatch = useDispatch();
  const spotifyState = useSelector(getSpotifyState);

  const isCurrentPlaylingTrack = spotifyState?.item?.id === data.track.id;

  const showPlayable = isCurrentPlaylingTrack && !spotifyState.paused;

  const renderArtistName = (artists) => artists.map((artist) => artist.name).join(', ');

  const handleItemClick = (uri) => dispatch(playTrack(uri));

  return (
    <PlayableItem
      imgUrl={data.track.album.images[2].url}
      imgAlt={data.track.name}
      onClick={() => handleItemClick(data.track.uri)}
      primaryText={data.track.name}
      showPlayable={showPlayable}
      secondaryText={renderArtistName(data.track.artists)}
      isCurrentItem={isCurrentPlaylingTrack}
      PlayableRenderer={() => (
        <TrackPlayable id={data.track.id} uri={data.track.uri} />
      )}
    />
  );
};

TrackItem.propTypes = {
  data: shape().isRequired,
};

export default TrackItem;
