import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTab, getNextApp, getIsAccountBoxOpen } from '../store/selectors';
import { changeTab, toggleAccountBox } from '../store/ui/ui';
import { BREAKPOINTS } from '../constants';

import { ReactComponent as IconAppSwitchDesktop } from '../assets/img/icon_app_switch_desktop.svg';
import { ReactComponent as IconSettings } from '../assets/img/icon_settings.svg';
import toggleFlowium from '../store/toggleFlowium';

const ScTabsHeader = styled.ul`
  width: 100%;
  height: 60px;
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #1b1b1b;
  padding: 5px;
  margin-bottom: 10px;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    background-color: #000000;
    padding: 14px 0 10px;
    margin-bottom: 0;
  }
`;

const Li = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ itemKey, activeKey }) => (itemKey === activeKey ? '#d8d8d8' : '#666666')};
  font-weight: 700;
  font-size: 10px;
  cursor: pointer;

  @media only screen and (min-width: ${BREAKPOINTS.SM_MIN}px) {
    &.settings {
      display: none;
    }
  }

  & svg > path,
  & svg > rect {
    fill: ${({ itemKey, activeKey }) => (itemKey === activeKey ? '#d8d8d8' : '#666666')};
  }

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    & svg > path,
    & svg > rect {
      fill: ${({ itemKey, activeKey }) => (itemKey === activeKey ? '#d8d8d8' : 'none')};
      stroke: ${({ itemKey, activeKey }) => (itemKey === activeKey ? '' : '#bababa')};
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: #ffffff;

    svg > path,
    svg > rect {
      fill: #d8d8d8;
    }

    @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
      svg > path,
      svg > rect {
        fill: ${({ itemKey, activeKey }) => (itemKey === activeKey ? '#d8d8d8' : 'none')};
        stroke: ${({ itemKey, activeKey }) => (itemKey === activeKey ? '' : '#bababa')};
      }
    }
  }
`;

const ScIconContainer = styled.div`
  margin-bottom: 7px;
`;

const TabsHeader = ({ children }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);
  const nextApp = useSelector(getNextApp);
  const isAccountBoxOpen = useSelector(getIsAccountBoxOpen);

  const handleTabClick = (tabKey) => {
    dispatch(changeTab(tabKey));
  };

  const handleAppSwitchClick = () => dispatch(toggleFlowium());

  const getNextAppName = () => `${nextApp[0].toUpperCase()}${nextApp.toLowerCase().slice(1)}`;

  return (
    <ScTabsHeader>
      <Li
        key="appSwitch"
        itemKey="appSwitch"
        activeKey={activeTab}
        onClick={handleAppSwitchClick}
      >
        <ScIconContainer>
          <IconAppSwitchDesktop />
        </ScIconContainer>
        {getNextAppName()}
      </Li>
      {children.map((tab) => (
        <Li
          key={tab.props.tabKey}
          itemKey={tab.props.tabKey}
          activeKey={activeTab}
          onClick={() => handleTabClick(tab.props.tabKey)}
        >
          <ScIconContainer>{tab.props.tabIcon}</ScIconContainer>
          {tab.props.tabLabel}
        </Li>
      ))}
      <Li
        key="settings"
        itemKey="settings"
        activeKey={activeTab}
        isOpen={isAccountBoxOpen}
        onClick={() => dispatch(toggleAccountBox())}
        className="settings js-settings"
      >
        <ScIconContainer>
          <IconSettings />
        </ScIconContainer>
        Settings
      </Li>
    </ScTabsHeader>
  );
};

TabsHeader.propTypes = {
  children: node,
};

TabsHeader.defaultProps = {
  children: null,
};

export default TabsHeader;
