import { fetchOriginalsPlaylists } from './entities/playlists';
import fetchSpotifyUser from './auth/fetchSpotifyUser';
import initPlayer from './player/actionCreators/initPlayer';
import { initCurrentViewingPlaylist, notificationCreated } from './ui/ui';
import { initializeAsyncFlow } from './auth/auth';
import { fetchPlaylistsContent } from './entities/playlistsContent';
import { notificationContentEN } from '../constants';

const initFlowium = () => (dispatch, getState) => {
  const user = getState().auth.user;
  const spotifyUser = getState().auth.spotifyUser
  const loading = getState().auth.loading;
  const isAsyncFlowInitialized = getState().auth.isAsyncFlowInitialized;

  if(!user || spotifyUser || loading || isAsyncFlowInitialized) return;

  dispatch(initializeAsyncFlow());

  return dispatch(fetchSpotifyUser())
    .then(() => dispatch(initPlayer()))
    .then(() => dispatch(fetchOriginalsPlaylists()))
    .then(() => dispatch(fetchPlaylistsContent()))
    .then(() => dispatch(initCurrentViewingPlaylist()))
    .catch(error => {
      console.log(error);
      dispatch(notificationCreated({ message: notificationContentEN.GENERIC_ERROR }));
    });
};

export default initFlowium;
