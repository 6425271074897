import React, { useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import QuotOpenButton from './QuotOpenButton';
import QuotBox from './QuotBox';
import {
  getArtwork,
  getScreenType,
} from '../store/selectors';
import { BREAKPOINTS, SCREEN_TYPES } from '../constants';

const ScArtworkWrapper = styled.div`
  overflow: auto;
  position: relative;
  height: calc(100% - 44px);
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScArtwork = styled.div`
  position: relative;
  border-radius: 10px;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    height: 31vh;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    opacity: 0.95;
    background: ${({ background }) => `bottom right / cover url(${background}) no-repeat;`};
  }
`;

const Artwork = () => {
  const artwork = useSelector(getArtwork);
  const wrapperRef = useRef();
  const screenType = useSelector(getScreenType);
  const { XS_SCREEN } = SCREEN_TYPES;

  return (
    <ScArtworkWrapper ref={wrapperRef}>
      <ScArtwork background={artwork}>
        {screenType !== XS_SCREEN && <QuotOpenButton ref={wrapperRef} />}
      </ScArtwork>
      <QuotBox />
    </ScArtworkWrapper>
  );
};

export default Artwork;

// background: bottom right / contain url(illustration_huseyin_ozkan_1.jpg) no-repeat, #050505;
// background: bottom right / contain url(illustration_faruk_gerger_1.jpeg) no-repeat, #050505;
