import { createAsyncThunk } from '@reduxjs/toolkit';
import { firestore, firebaseAuth } from '../../utils/firebase';

const fetchFlowiumUser = createAsyncThunk(
  'auth/fetchUser',
  async () => {
    const { currentUser } = firebaseAuth;
    if (!currentUser) return;

    const { uid } = currentUser;
    const userResp = await firestore.collection('users').doc(uid).get();
    const user = userResp.data();

    // eslint-disable-next-line consistent-return
    return user
      ? { ...user, created_date: user.created_date.toString() }
      : false;
  },
);

export default fetchFlowiumUser;

