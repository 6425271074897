import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ScFullScreenButton from './sc/scFullScreen';
import toggleFullScreen from '../../store/player/actionCreators/toggleFullScreen';

import { ReactComponent as IconFullScreenEnter } from '../../assets/img/icon_fullscreen_enter.svg';
import { ReactComponent as IconFullScreenExit } from '../../assets/img/icon_fullscreen_exit.svg';

import { getIsFullScreen } from '../../store/selectors';

const FullScreen = () => {
  const dispatch = useDispatch();
  const isFullScreen = useSelector(getIsFullScreen);

  useEffect(() => {
    if (isFullScreen) {
      global.document.documentElement.requestFullscreen();
      return;
    }

    if (!isFullScreen && global.document.fullscreenElement) {
      global.document.exitFullscreen();
    }
  }, [isFullScreen]);

  const handleFullScreenClick = () => {
    dispatch(toggleFullScreen());
  };

  return (
    <ScFullScreenButton onClick={handleFullScreenClick}>
      {isFullScreen ? <IconFullScreenExit /> : <IconFullScreenEnter />}
    </ScFullScreenButton>
  );
};

export default FullScreen;
