import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeScreenType } from '../store/ui/ui';
import debounce from 'lodash/debounce';

// init screen type
const useResize = () => {
  const dispatch = useDispatch();

    useEffect(() => {
      const handleResize = () => {
        dispatch(changeScreenType(global.innerWidth));
      };
      handleResize();
      const debouncedHandleResize = debounce(handleResize, 300);
      global.addEventListener('resize', debouncedHandleResize);
      return () => {
        global.removeEventListener('resize', debouncedHandleResize);
      };
    }, [dispatch]);
};

export default useResize;

