import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { BREAKPOINTS } from './constants';
import { getUser, getIsAccountBoxOpen } from './store/selectors';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

// components
import Content from './components/Content';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Player from './components/Player/Player';
import NotificationContainer from './components/NotificationContainer';
import Login from './components/Login';
import useResize from './hooks/useResize';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #000000;
`;

const ScBlurWrapper = styled.div`
  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    transition: 200ms ease-out;
    ${({ isAcountBoxOpen }) => (isAcountBoxOpen
    ? 'transform: translateX(-300px); filter: blur(4px);'
    : '')}
  }
`;

const MainDiv = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  & .content-area {
    width: 100%;
    position: relative;
    padding: 0 0 10px 10px;

    @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
      padding: 0;
    }
  }
`;

const App = () => {
  const isAcountBoxOpen = useSelector(getIsAccountBoxOpen);
  const user = useSelector(getUser);

  useResize();

  return (
    <>
      <Wrapper>
        <Header />
        <ScBlurWrapper isAcountBoxOpen={isAcountBoxOpen}>
          <MainDiv>
            <Sidebar />
            <Content />
          </MainDiv>
          <Player />
        </ScBlurWrapper>
        <div id="mobile-bottom-nav" />
        <NotificationContainer />
      </Wrapper>
      {!user && <Login />}
    </>
  );
};

export default App;
