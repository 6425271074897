import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from './reducer';
import spotifyWebApiMiddleware from './middleware/spotifyWebApiMiddleware';
import refreshTokenMiddleware from './middleware/refreshTokenMiddleware';

export default function () {
  return configureStore({
    reducer,
    middleware: [
      ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
      }),
      spotifyWebApiMiddleware,
      refreshTokenMiddleware
    ],
  });
}
