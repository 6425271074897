import axios from 'axios';
import { spotifyApiPending, spotifyApiFulfilled, spotifyApiRejected } from '../spotifyApi';
import { notificationCreated } from '../ui/ui';
import { refreshSpotifyTokenRequested } from '../auth/auth';
import { notificationContentEN } from '../../constants';

const spotifyWebApiMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  if (action.type !== spotifyApiPending.type) {
    return next(action);
  }

  next(action);

  const {
    url,
    method = 'get',
    params,
    data,
    onPending,
    onFulfilled,
    onRejected,
  } = action.payload;

  if (onPending) {
    dispatch({ type: onPending });
  }

  const accessToken = getState().auth.user.access_token;

  let request = {
    url,
    method,
    baseURL: 'https://api.spotify.com',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  if (params) {
    request = { ...request, params };
  }

  if (data) {
    request = { ...request, data };
  }

  try {
    const response = await axios.request(request);
    if (onFulfilled) {
      dispatch({ type: onFulfilled, payload: response.data });
    }

    return dispatch(spotifyApiFulfilled());

  } catch (error) {
    if (onRejected) {
      dispatch({ type: onRejected, payload: { error: error?.response?.data?.error }});
    }

    dispatch(spotifyApiRejected());

    // 401 Unauthorized
    if (error?.response?.data?.error?.status === 401) {
      return dispatch(refreshSpotifyTokenRequested({ failedAction: action }));
    }

    dispatch(notificationCreated({
      message: notificationContentEN.GENERIC_ERROR,
    }));
  }

  return Promise.resolve();
};

export default spotifyWebApiMiddleware;
