import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { ReactComponent as IconWarning } from '../assets/img/icon_warning.svg';

const StyledNotification = styled.div`
  height: 100%;
  padding: 13px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .notification-icon {
    width: 29px;
  }

  .notification-text {
    font-size: 12px;
    color: #d8d8d8;
    margin-left: 13px;
  }
`;

const Notification = ({ message }) => (
  <StyledNotification>
    <div className="notification-icon">
      <IconWarning />
    </div>
    <div className="notification-text">{message}</div>
  </StyledNotification>
);

Notification.propTypes = {
  message: string.isRequired,
};

export default Notification;
