import React, { useEffect } from 'react';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Notification from './Notification';
import { getNotification } from '../store/selectors';

const ScWrapper = styled.div`
  .Toastify__toast-container {
    z-index: 99;
  }
  
  .Toastify__toast-container--bottom-left {
    left: 425px;
    bottom: 96px;
  }
`;

const NotificationContainer = () => {
  const notification = useSelector(getNotification);

  useEffect(() => {
    const hasNotification = Object.keys(notification).length;
    if (!hasNotification) return;

    toast.dismiss();

    toast(
      <Notification message={notification.message} />,
      { ...notification.options },
    );
  }, [notification]);

  return (
    <ScWrapper>
      <ToastContainer
        position="bottom-left"
        hideProgressBar
        transition={Zoom}
        autoClose={3500}
        limit={1}
      />
    </ScWrapper>
  );
};

export default NotificationContainer;
