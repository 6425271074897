import { playerDisconnected } from '../player';
import { flowiumPlayer } from './initPlayer';

const disconnectPlayer = () => (dispatch, getState) => {
  return flowiumPlayer.disconnect().then(success => {
    if (success) {
      return dispatch(playerDisconnected());
    }
  });
};

export default disconnectPlayer;
