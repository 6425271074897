import { prevPlayed } from '../player';
import { flowiumPlayer } from './initPlayer';

export const playPrevURI = () => (dispatch, getState) => {
  if (!getState().player.spotifyState) return;

  flowiumPlayer.previousTrack();
  dispatch(prevPlayed());
};

export default playPrevURI;
