import { combineReducers } from 'redux';
import playlistsReducer from './playlists';
import tracksReducer from './tracks';
import artistsReducer from './artists';
import playlistsContentReducer from './playlistsContent';

export default combineReducers({
  playlists: playlistsReducer,
  tracks: tracksReducer,
  artists: artistsReducer,
  playlistsContent: playlistsContentReducer
});
