import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getScreenType } from '../store/selectors';
import { SCREEN_TYPES } from '../constants';
import useLogin from '../hooks/useLogin';

const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  & img {
    width: 30px;
    height: 30px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    margin-right: 7px;
  }

  & p,
  a {
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin: 4px 0 0 0;

    &:hover {
      text-decoration: none;
      color: white;
      cursor: pointer;
    }
  }
`;

const ScCtaButton = styled.button`
  background-color: #ab3b3b;
  font-size: 14px;
  font-weight: 700;
  border: none;
  color: #ffffff;
  padding: 7px 10px;
  border-radius: 5px;
  margin-left: 15px;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
`;

const AccountBoxGuest = () => {
  const screenType = useSelector(getScreenType);
  const handleLogin = useLogin();

  return (
    <Div>
      <ScCtaButton type="button" onClick={handleLogin}>
        {screenType === SCREEN_TYPES.XS_SCREEN ? 'Login' : 'Login with Spotify'}
      </ScCtaButton>
    </Div>
  );
};

export default AccountBoxGuest;
