import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createNotification } from '../store/ui/ui';
import signInFlowium from '../store/auth/signInFlowium';
import { notificationContentEN } from '../constants';

const useLogin = () => {
  let spotifyAuthWindowRef = null;
  const dispatch = useDispatch();

  const handleSpotifyLogin = () => {
    if (spotifyAuthWindowRef == null || spotifyAuthWindowRef.closed) {
      spotifyAuthWindowRef = global.open(
        `${process.env.REACT_APP_FIREBASE_API_URL}/auth/spotify-auth-window`,
        '_blank',
        'width=640,height=800',
      );
    } else {
      spotifyAuthWindowRef.focus();
    }
  };

  const handlePostMessage = ({ data, origin }) => {
    if (!data || origin !== global.location.origin) return;

    if (data.error) {
      dispatch(
        createNotification({
          message: `${data.error}/${data.status}`,
          options: { autoClose: false },
        }),
      );
    } else if (data.status === 'login-succeeded') {
      dispatch(
        createNotification({ message: notificationContentEN.LOGIN_SUCCEEDED }),
      );
      dispatch(signInFlowium(data));
    }
  };

  useEffect(() => {
    global.addEventListener('message', handlePostMessage);
    return () => {
      global.removeEventListener('message', handlePostMessage);
    };
  });

  return handleSpotifyLogin;
};

export default useLogin;
