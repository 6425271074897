import styled from 'styled-components';
import { BREAKPOINTS } from '../../../constants';

const ScProgressBar = styled.div`
  width: 100%;
  height: 4px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    display: none;
  }

  .bar-wrapper {
    width: 100%;
    height: 4px;
    position: relative;
    overflow: hidden;
  }

  .bar {
    width: 100%;
    height: 4px;
    border-radius: 1px;
    cursor: pointer;

    &.track-bar {
      background-color: #2b2b2b;
    }

    &.track-progress {
      background-color: #d8d8d8;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
    }
  }

  .time {
    width: 45px;
    font-size: 12px;
    color: #d8d8d8;
    text-align: center;
  }
`;

export default ScProgressBar;
