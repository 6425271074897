import { paused } from '../player';
import { flowiumPlayer } from './initPlayer';

export const pauseURI = () => (dispatch, getState) => {
  if (!getState().player.spotifyState) return;
  if (getState().player.spotifyState.paused) return;

  flowiumPlayer.pause();
  dispatch(paused());
};

export default pauseURI;
