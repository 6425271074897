/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { firestore } from '../../utils/firebase';

export const fetchPlaylistsContent = createAsyncThunk(
  'playlistContent/fetch',
  async () => {
    const snapshot = await firestore.collection('playlistsContent').get();
    return snapshot.docs.map((doc) => doc.data());
  },
);

const slice = createSlice({
  name: 'playlistsContent',
  initialState: {
    data: {},
    loading: false,
    errorMessage: null,
  },
  reducers: {},
  extraReducers: {
    [fetchPlaylistsContent.pending]: (state) => {
      state.loading = true;
    },
    [fetchPlaylistsContent.rejected]: (state) => {
      state.loading = false;
    },
    [fetchPlaylistsContent.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload) {
        action.payload.forEach((content) => {
          state.data[content.playlist_id] = content;
        });
      }
    },
  },
});

// get playlists content
export const getPlaylistsContent = () => (dispatch, getState) => {
  const { playlistsContent } = getState().entities;
  if (
    Object.keys(playlistsContent.data).length !== 0 && playlistsContent.loading
  ) {
    return;
  }
  dispatch(fetchPlaylistsContent());
};

export default slice.reducer;
