import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { node } from 'prop-types';
import fetchFlowiumUser from '../store/auth/fetchFlowiumUser';
import { firebaseAuth } from '../utils/firebase';
import initFlowium from '../store/initFlowium';
import { getUser } from '../store/selectors';
import changeUserStatus from '../store/auth/userAuthChanged';

const AccountBox = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((usr) => {
      if (!usr) {
        dispatch(changeUserStatus());
      } else {
        dispatch(fetchFlowiumUser());
      }
    });
  }, [dispatch]);


  useEffect(() => {
    dispatch(initFlowium());
  }, [dispatch, user]);

  return <>{children}</>;
};

AccountBox.propTypes = {
  children: node,
};

AccountBox.defaultProps = {
  children: null,
};

export default AccountBox;
