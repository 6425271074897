import axios from 'axios';
import { firebaseAuth } from '../../utils/firebase';
import {
  refreshSpotifyTokenRequested,
  refreshSpotifyTokenFulfilled,
  refreshSpotifyTokenRejected,
} from '../auth/auth';
import disconnectPlayer from '../player/actionCreators/disconnectPlayer';

const refreshTokenMiddleware = ({ getState, dispatch }) => (next) => async (action) => {
  if (action.type !== refreshSpotifyTokenRequested.type) {
    return next(action);
  }

  next(action);

  const { failedAction } = action?.payload || {};

  try {
    const token = await firebaseAuth.currentUser.getIdToken();
    const response = await axios.request({
      url: `${process.env.REACT_APP_FIREBASE_API_URL}/auth/spotify-refresh-token`,
      method: 'post',
      data: { token },
    });

    if (failedAction) {
      dispatch(refreshSpotifyTokenFulfilled({ accessToken: response.data.access_token }));
      return dispatch(failedAction);
    } else {
      return dispatch(refreshSpotifyTokenFulfilled({ accessToken: response.data.access_token }));
    }

  } catch (error) {
    console.log(error);
    console.log('config', error.config);
    console.log('request', error.request);
    console.log('response', error.response);
    console.log('message', error.message);
    dispatch({
      type: refreshSpotifyTokenRejected.type,
      payload: { error: error.response?.data?.error || error?.message }
    });
    dispatch(disconnectPlayer());

    return Promise.reject(error.response?.data?.error || error?.message);
  }
};

export default refreshTokenMiddleware;
