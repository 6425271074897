import { spotifyApiPending } from '../../spotifyApi';
import {
  playbackTransferRequested,
  playbackTransferFulfilled,
  playbackTransferRejected
} from '../player';

const transferPlayback = (deviceID, play = false) => ( dispatch ) => {
  if (!deviceID) return;

  return dispatch(
    spotifyApiPending({
      url: '/v1/me/player',
      method: 'put',
      data: { device_ids: [deviceID], play },
      onPending: playbackTransferRequested.type,
      onFulfilled: playbackTransferFulfilled.type,
      onRejected: playbackTransferRejected.type,
    }),
  );
};

export default transferPlayback;
