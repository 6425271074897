import React, { useEffect } from 'react';
import { elementType } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { changePlaylistTitlePosition } from '../store/ui/ui';
import { getSelectionsPlaylistTitle } from '../store/selectors';
import useFixedOnScrollInContainer from '../hooks/useFixedOnScrollInContainer';

const PlaylistTitle = ({ TitleRenderer, FixedTitleRenderer }) => {
  const playlistTitle = useSelector(getSelectionsPlaylistTitle);
  const isTitleFixed = useFixedOnScrollInContainer(230, '#innerScrollList');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changePlaylistTitlePosition(isTitleFixed));
  }, [isTitleFixed, dispatch]);

  return (
    <>
      <TitleRenderer>
        {playlistTitle}
      </TitleRenderer>
      <FixedTitleRenderer isFixed={isTitleFixed}>
        {playlistTitle}
      </FixedTitleRenderer>
    </>
  );
};

PlaylistTitle.propTypes = {
  TitleRenderer: elementType,
  FixedTitleRenderer: elementType,
};

PlaylistTitle.defaultProps = {
  TitleRenderer: () => null,
  FixedTitleRenderer: () => null,
};

export default PlaylistTitle;
