import { combineReducers } from 'redux';
import entitiesReducer from './entities/entities';
import authReducer from './auth/auth';
import playerReducer from './player/player';
import uiReducer from './ui/ui';

export default combineReducers({
  entities: entitiesReducer,
  auth: authReducer,
  player: playerReducer,
  ui: uiReducer,
});
