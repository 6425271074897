import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ScLikeButton, ScTrack } from './sc/scTrack';
import { getSpotifyState, getIsCurrentTrackSaved } from '../../store/selectors';
import { toggleCurrentTrackSave } from '../../store/player/actionCreators/manageSavedTracks';

import { ReactComponent as IconHeart } from '../../assets/img/icon_heart.svg';

const Track = () => {
  const dispatch = useDispatch();
  const spotifyState = useSelector(getSpotifyState);
  const isCurrentTrackLiked = useSelector(getIsCurrentTrackSaved);

  if (!spotifyState) {
    return <ScTrack />;
  }

  const currentTrack = spotifyState?.track_window?.current_track;
  const trackName = currentTrack?.name;
  const artistNames = currentTrack?.artists.map(artist => artist.name).join(', ');

  const handleTrackLikeToggle = () => {
    dispatch(toggleCurrentTrackSave());
  };

  return (
    <ScTrack>
      <div className="track-info">
        <span className="track-name">{trackName}</span>
        <span className="track-artist">{artistNames}</span>
      </div>
      <ScLikeButton onClick={handleTrackLikeToggle} liked={isCurrentTrackLiked}>
        <IconHeart />
      </ScLikeButton>
    </ScTrack>
  );
};

export default Track;
