import React from 'react';
import { ScFlowiumPlayer, ScControlsWrapper } from './sc/scPlayer';
import Track from './Track';
import Controls from './Controls';
import ProgressBar from './ProgressBar';
import Volume from './Volume';

const FlowiumPlayer = () => (
  <ScFlowiumPlayer>
    <Track />
    <ScControlsWrapper>
      <Controls />
      <ProgressBar />
    </ScControlsWrapper>
    <Volume />
  </ScFlowiumPlayer>
);

export default FlowiumPlayer;
