import { initialVolumeRequested, initialVolumeReceived } from '../player';
import { flowiumPlayer } from './initPlayer';

const getInitialVolume = () => (dispatch, getState) => {
  dispatch(initialVolumeRequested());
  flowiumPlayer.getVolume().then((volume) => {
    dispatch(initialVolumeReceived({ initialVolume: volume * 100 }));
  });
};

export default getInitialVolume;
