import styled from 'styled-components';
import { BREAKPOINTS } from '../../../constants';

const ScControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 190px;
  margin-bottom: 17px;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    width: 261px;
  }
`;

export default ScControls;
