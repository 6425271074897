import styled from 'styled-components';
import { BREAKPOINTS } from '../../../constants';

export const ScFlowiumPlayer = styled.div`
  width: 100%;
  height: 90px;
  background: #1b1b1b;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  padding: 0 31px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    background: #2b2b2b;
    padding: 15px 14px;
    flex-direction: column;
    height: auto;
    min-height: 123px;
  }
`;

export const ScControlsWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    width: auto;
  }
`;
