import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Tabs from './Tabs';
import Tab from './Tab';
import List from './List';
import PlaylistItem from './PlaylistItem';
import TrackItem from './TrackItem';

import { ReactComponent as IconPlaylistsDesktop } from '../assets/img/icon_playlists_desktop.svg';
import { ReactComponent as IconTracksDesktop } from '../assets/img/icon_tracks_desktop.svg';

import { BREAKPOINTS, TABS } from '../constants';

import {
  getCurrentPlaylistsData,
  getCurrentTracksData
} from '../store/selectors';

const StyledSidebar = styled.div`
  width: 320px;
  height: 100%;
  padding-bottom: 10px;
  border-radius: 10px;
  flex-shrink: 0;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    width: 100%;
  }
`;

const Sidebar = () => {
  const playlistsData = useSelector(getCurrentPlaylistsData);
  const tracksData = useSelector(getCurrentTracksData);

  return (
    <StyledSidebar>
      <Tabs>
        {/* playlists */}
        <Tab
          tabKey={TABS.PLAYLISTS}
          tabLabel="Playlists"
          tabIcon={<IconPlaylistsDesktop />}
        >
          <List
            listData={playlistsData}
            ItemRenderer={PlaylistItem}
          />
        </Tab>
        {/* tracks */}
        <Tab
          tabKey={TABS.TRACKS}
          tabLabel="Tracks"
          tabIcon={<IconTracksDesktop />}>
            <List
              listData={tracksData}
              ItemRenderer={TrackItem}
            />
        </Tab>
      </Tabs>
    </StyledSidebar>
  );
};

export default Sidebar;
