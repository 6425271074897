import { resumed } from '../player';
import { flowiumPlayer } from './initPlayer';

export const resumeURI = () => (dispatch, getState) => {
  if (!getState().player.spotifyState) return;
  if (!getState().player.spotifyState.paused) return;

  flowiumPlayer.resume();
  dispatch(resumed());
};

export default resumeURI;
