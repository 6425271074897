import React from 'react';
import styled from 'styled-components';
import useLogin from '../hooks/useLogin';
import ScNoStyleButton from './sc/ScNoStyleButton';
import { BREAKPOINTS } from '../constants';

import { ReactComponent as LogoFlowiumwithMotto } from '../assets/img/logo_flowium_with_motto.svg';
import { useSelector } from 'react-redux';
import { getAuthLoading } from '../store/selectors';

const ScLogin = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: center / cover url('bg_login.jpg') no-repeat;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ScLogoWrapper = styled.div`
  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    margin: auto 0;
  }
`;

const ScLoginButton = styled(ScNoStyleButton)`
  border-radius: 10px;
  border: 2px solid #dadada;
  padding: 20px 50px;
  font-size: 20px;
  font-weight: 700;
  color: #dadada;
  line-height: 1;
  margin: 35px 0 0 0;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    width: calc(100% - 100px);
    padding: 20px 0;
    margin: 0 0 50px 0;
  }

  &:hover {
    border: 2px solid #ffffff;
    color: #ffffff;
  }
`;

const Login = () => {
  const handleLogin = useLogin();
  const authLoading = useSelector(getAuthLoading);

  return (
    <ScLogin>
      <ScLogoWrapper>
        <LogoFlowiumwithMotto />
      </ScLogoWrapper>
      {!authLoading && (
        <ScLoginButton type="button" onClick={handleLogin}>
          Login with Spotify
        </ScLoginButton>
      )}
    </ScLogin>
  );
};

export default Login;
