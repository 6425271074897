import { spotifyApiPending } from '../../spotifyApi';

const toggleRepeatMode = () => (dispatch, getState) => {
  if (!getState().player.spotifyState) return;

  const repeatModeSwitcherMap = {
    0: 1,
    1: 2,
    2: 0,
  };

  const repeatModeMap = {
    0: 'off',
    1: 'context',
    2: 'track',
  };

  const { deviceID } = getState().player;
  const currentRepeatMode = getState().player.spotifyState.repeat_mode;
  const nextRepeatMode = repeatModeSwitcherMap[currentRepeatMode];

  const repeatValue = repeatModeMap[nextRepeatMode];

  dispatch(
    spotifyApiPending({
      url: '/v1/me/player/repeat',
      method: 'put',
      params: { id: deviceID, state: repeatValue },
    }),
  );
};

export default toggleRepeatMode;
