import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getCurrentViewingPlaylistID, getPlaylistsContentData } from '../store/selectors';
import { BREAKPOINTS } from '../constants';

const StyledQuotation = styled.div`
  color: #dadada;
  flex-basis: 70%;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    margin-bottom: 30px;
  }

  p {
    font-size: 16px;
    line-height: 180%;
    margin-bottom: 15px;

    @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
      font-size: 14px;
      line-height: 140%;
    }
  }

  span {
    font-size: 16px;
    line-height: 180%;
    font-weight: 700;

    @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
      font-size: 14px;
      line-height: 140%;
    }
  }
`;

const Quotation = () => {
  const contents = useSelector(getPlaylistsContentData);
  const currentViewingPlaylistID = useSelector(getCurrentViewingPlaylistID);
  const currentContent = contents[currentViewingPlaylistID];

  if (!currentContent) return null;

  return (
    <StyledQuotation>
      <p dangerouslySetInnerHTML={{ __html: currentContent.content }} />
      <span>{currentContent.quote_from}</span>
    </StyledQuotation>
  );
};

export default Quotation;
