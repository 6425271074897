import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import Playable from './Playable';
import { playTrack } from '../store/entities/tracks';
import { getSpotifyState } from '../store/selectors';
import pauseURI from '../store/player/actionCreators/pauseURI';
import resumeURI from '../store/player/actionCreators/resumeURI';

const TrackPlayable = ({ id, uri }) => {
  const dispatch = useDispatch();
  const spotifyState = useSelector(getSpotifyState);

  let isCurrentPlaylingTrack = spotifyState?.track_window?.current_track.id === id;

  const handlePlayClick = (e, itemUri) => {
    e.stopPropagation();

    if (isCurrentPlaylingTrack && !spotifyState.paused) {
      dispatch(pauseURI());
      return;
    } if (isCurrentPlaylingTrack && spotifyState.paused) {
      dispatch(resumeURI());
      return;
    }

    dispatch(playTrack(itemUri));
  };

  return (
    <Playable
      onClick={(e) => handlePlayClick(e, uri)}
      renderPauseCondition={isCurrentPlaylingTrack && !spotifyState.paused}
    />
  );
};

TrackPlayable.propTypes = {
  id: string.isRequired,
  uri: string.isRequired,
};

export default TrackPlayable;
