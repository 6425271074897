import styled from 'styled-components';
import ScNoStyleButton from '../../sc/ScNoStyleButton';
import { BREAKPOINTS } from '../../../constants';

export const ScLikeButton = styled(ScNoStyleButton)`
  svg {
    width: 21px;
    height: 19px;
  }

  svg {
    path,
    rect {
      fill: ${({ liked }) => (liked ? '#bababa' : '')};
      stroke: #bababa;
    }
  }

  &:hover svg {
    path,
    rect {
      fill: ${({ liked }) => (liked ? '#ffffff' : '')};
      stroke: #ffffff;
    }
  }
`;

export const ScTrack = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  line-height: 1;

  & .track-info {
    margin-right: 19px;
  }

  & .track-name {
    display: block;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 6px;
  }

  & .track-artist {
    display: block;
    font-size: 12px;
    color: #cecece;
  }

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    width: 100%;
    margin-bottom: 24px;

    & .track-info {
      margin-right: auto;
    }
  }
`;
