import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getVolumePercent } from '../../store/selectors';
import changeVolume from '../../store/player/actionCreators/changeVolume';

import { ScVolume, ScVolumeButton, ScVolumeBar } from './sc/scVolume';
import FullScreen from './FullScreen';

import { ReactComponent as IconVolumeHigh } from '../../assets/img/icon_volume_high.svg';
import { ReactComponent as IconVolumeMid } from '../../assets/img/icon_volume_mid.svg';
import { ReactComponent as IconVolumeLow } from '../../assets/img/icon_volume_low.svg';
import { ReactComponent as IconVolumeMute } from '../../assets/img/icon_volume_mute.svg';

const Volume = () => {
  const dispatch = useDispatch();
  const volumePercent = useSelector(getVolumePercent);
  const [locVolumePercent, setLocVolumePercent] = useState(volumePercent);

  const handleVolumeChange = ({ target: { value }}) => {
    setLocVolumePercent(value);
    dispatch(changeVolume(value));
  };

  const handleMuteToggle = () => {
    if (locVolumePercent === 0) {
      dispatch(changeVolume(50));
      return;
    }
    dispatch(changeVolume(0));
  };

  const renderVolumeIcon = () => {
    if (locVolumePercent === 0) {
      return <IconVolumeMute />;
    }
    if (locVolumePercent < 40) {
      return <IconVolumeLow />;
    }
    if (locVolumePercent < 70) {
      return <IconVolumeMid />;
    }
    return <IconVolumeHigh />;
  };

  return (
    <ScVolume>
      {/* volume control */}
      <div className="volume-control">
        <ScVolumeButton onClick={handleMuteToggle}>
          {renderVolumeIcon()}
        </ScVolumeButton>
        <ScVolumeBar volume={locVolumePercent}>
          <input
            type="range"
            min="0"
            max="100"
            value={locVolumePercent}
            onChange={handleVolumeChange}
          />
          <div className="volume-bar-fill" />
        </ScVolumeBar>
      </div>
      <FullScreen />
    </ScVolume>
  );
};

export default Volume;
