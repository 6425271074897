import React from 'react';
import styled from 'styled-components';
import { bool, func } from 'prop-types';
import { ReactComponent as IconSettings } from '../assets/img/icon_settings.svg';
import ScNoStyleButton from './sc/ScNoStyleButton';
import { BREAKPOINTS } from '../constants';

const ScIcon = styled(ScNoStyleButton)`
  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    display: none;
  }

  &:hover,
  &:active,
  &:focus {
    svg > path,
    svg > rect {
      stroke: #d8d8d8;
    }
  }

  & svg > path,
  & svg > rect {
    stroke: ${({ isOpen }) => (isOpen ? '#d8d8d8' : '#bababa')};
  }
`;

const SettingsButtonStandalone = ({ isOpen, onClick }) => (
  <ScIcon isOpen={isOpen}>
    <IconSettings type="button" onClick={onClick} />
  </ScIcon>
);

SettingsButtonStandalone.propTypes = {
  isOpen: bool,
  onClick: func,
};

SettingsButtonStandalone.defaultProps = {
  isOpen: false,
  onClick: (f) => f,
};

export default SettingsButtonStandalone;
