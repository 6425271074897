import { spotifyApiPending } from '../spotifyApi';
import { spotifyUserRequested, spotifyUserFulfilled, spotifyUserRejected } from './auth';

const fetchSpotifyUser = () => (dispatch, getState) => {
  const { auth } = getState();
  const { user, isSpotifyUserFetched } = auth;
  if (auth.loading || !user || isSpotifyUserFetched) return false;

  return dispatch(
    spotifyApiPending({
      url: '/v1/me',
      onPending: spotifyUserRequested.type,
      onFulfilled: spotifyUserFulfilled.type,
      onRejected: spotifyUserRejected.type,
    }),
  );
};

export default fetchSpotifyUser;
