import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import Playable from './Playable';
import { playPlaylist } from '../store/entities/playlists';
import { changePlaylist } from '../store/ui/ui';
import pauseURI from '../store/player/actionCreators/pauseURI';
import resumeURI from '../store/player/actionCreators/resumeURI';
import { getSpotifyState } from '../store/selectors';

const PlaylistPlayable = ({ id, uri }) => {
  const dispatch = useDispatch();
  const spotifyState = useSelector(getSpotifyState);
  const isCurrentPlayingPlaylist = spotifyState?.context?.uri === uri;

  const handlePlayClick = (e, itemId, itemUri) => {
    e.stopPropagation();

    if (isCurrentPlayingPlaylist && !spotifyState.paused) {
      dispatch(pauseURI());
      return;
    } if (isCurrentPlayingPlaylist && spotifyState.paused) {
      dispatch(resumeURI());
      return;
    }

    dispatch(playPlaylist(itemId, itemUri));
    dispatch(changePlaylist(itemId, itemUri));
  };

  return (
    <Playable
      onClick={(e) => handlePlayClick(e, id, uri)}
      renderPauseCondition={isCurrentPlayingPlaylist && !spotifyState.paused}
    />
  );
};

PlaylistPlayable.propTypes = {
  id: string.isRequired,
  uri: string.isRequired,
};

export default PlaylistPlayable;
