import React from 'react';
import styled from 'styled-components';
import { array, elementType } from 'prop-types';

import { BREAKPOINTS, SCREEN_TYPES } from '../constants';
import { useSelector } from 'react-redux';
import { getIsPlaylistTitleFixed, getIsPlayButtonFixed } from '../store/selectors';
import MobilePlayButton from './MobilePlayButton';
import { getScreenType, getActiveTab } from '../store/selectors';
import { memo } from 'react';

const ScInnerScrollList = styled.ul`
  height: calc(100vh - 236px);
  overflow: auto;
  border-radius: 10px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    height: calc(100vh - 253px);
  }
`;
const ScPlayWrapper = styled.span``;

const ScMobilePlayContainer = styled.li`
  position: relative;

  & ${ScPlayWrapper} {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(50%);
    z-index: 5;

    ${({ isPlayButtonFixed }) => {
      if (isPlayButtonFixed) {
        return `
          position: fixed;
          bottom: initial;
          top: 74px;
          right: 10px;
          z-index: 50;
          transform: none;
      `;
      }
    }}
  }
`;

const List = ({ listData, ItemRenderer }) => {
  const isPlaylistTitleFixed = useSelector(getIsPlaylistTitleFixed);
  const isPlayButtonFixed = useSelector(getIsPlayButtonFixed);
  const screenType = useSelector(getScreenType);
  const activeTab = useSelector(getActiveTab);
  const { XS_SCREEN } = SCREEN_TYPES;

  return (
    <ScInnerScrollList
      id="innerScrollList"
      isPlaylistTitleFixed={isPlaylistTitleFixed}
    > 
      {/* mobile artwork container */}
      <li id="mobile-artwork-container" />
      {/* mobile playlist play button */}
      {screenType === XS_SCREEN && activeTab === 'tracks' && (
        <ScMobilePlayContainer isPlayButtonFixed={isPlayButtonFixed}>
          <ScPlayWrapper>
            <MobilePlayButton />
          </ScPlayWrapper>
        </ScMobilePlayContainer>
      )}
      {/* list items */}
      {listData && listData.map(item => (
        <ItemRenderer
          data={item}
          key={item?.id || item?.track?.id}
        />))
      }
    </ScInnerScrollList>
  );
};

List.propTypes = {
  listData: array,
  ItemRenderer: elementType.isRequired
};

List.defaultProps = {
  listData: []
};

export default memo(List);
