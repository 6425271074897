import styled from 'styled-components';
import ScIconWrapper from '../../sc/ScIconWrapper';
import { BREAKPOINTS } from '../../../constants';

export const ScVolumeButton = styled(ScIconWrapper)`
  width: 22px;
  height: 16px;
  margin-right: 10px;
`;

export const ScVolumeBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;

  & input[type='range'] {
    width: 100%;
    height: 4px;
    background: #2b2b2b;
    border-radius: 1px;
    appearance: none;
    outline: none;
    cursor: pointer;

    &::-webkit-slider-thumb {
      appearance: none;
      opacity: 0;
      transition: opacity 200ms;
      position: relative;
      z-index: 1;
    }

    &:hover::-webkit-slider-thumb {
      opacity: 1;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background-color: #bd3030;
    }
  }

  & .volume-bar-fill {
    background: #cecece;
    width: ${({ volume }) => volume}%;
    height: 4px;
    border-radius: 1px;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
`;

export const ScVolume = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & .volume-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 28px;
  }

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    display: none;
  }
`;
