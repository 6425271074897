import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ReactComponent as LogoSelections } from '../assets/img/logo_flowium_selections.svg';
import { ReactComponent as LogoOriginals } from '../assets/img/logo_flowium_originals.svg';
import { BREAKPOINTS, APP_NAMES } from '../constants';
import { getCurrentApp } from '../store/selectors';

const A = styled.a`
  margin: 0 auto 0 30px;

  @media only screen and (max-width: ${BREAKPOINTS.XS_MAX}px) {
    margin: 0 auto 0 9px;
  }
`;

const Logo = () => {
  const { ORIGINALS } = APP_NAMES;
  const currentApp = useSelector(getCurrentApp);

  return (
    <A href="#">
      {currentApp === ORIGINALS ? <LogoOriginals /> : <LogoSelections />}
    </A>
  );
};

export default Logo;
