import { shape } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InstagramLogo } from '../assets/img/logo_instagram.svg';
import { ReactComponent as LinkedInLogo } from '../assets/img/logo_linkedin.svg';

const ScContDetails = styled.div`
  width: calc(100% - 100px);
  background-color: #2b2b2b;
  padding: 10px;
  border-radius: 5px;
    color: #ffffff;
  margin-top: 10px;

  .bio {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .social-link {
    display: inline-block;
    margin-right: 12px;
  }
`;

const ContributerDetails = ({ contributorData }) => {
  const { biography, external_links: externalLinks } = contributorData;

  return (
    <ScContDetails>
      <p className="bio">{biography}</p>
      <div>
        {externalLinks[0] && (
          <a
            className="social-link"
            href={externalLinks[0]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramLogo />
          </a>
        )}
        {externalLinks[1] && (
          <a
            className="social-link"
            href={externalLinks[1]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInLogo />
          </a>
        )}
      </div>
    </ScContDetails>
  );
};

ContributerDetails.propTypes = {
  contributorData: shape(),
};

ContributerDetails.defaultProps = {
  contributorData: {},
};

export default ContributerDetails;
